/* istanbul ignore file */
export class Settings {
    static KULTURIO_API_URL = process.env.GATSBY_REACT_APP_API_URL;
    static KULTURIO_API_KEY = process.env.GATSBY_REACT_APP_KULTURIO_API_KEY;
    static PAGE_URL= process.env.GATSBY_REACT_APP_URL;
    static TIDSROM_ZONE_IDS = process.env.GATSBY_REACT_APP_TIDSROM_ZONE_IDS.split(",");
    static TIDSROM_APP_SETTINGS_ID = process.env.GATSBY_REACT_APP_TIDSROM_APP_SETTINGS_ID;
    static TIDSROM_TIMELINE_SCHEMA_ID = process.env.GATSBY_REACT_APP_TIDSROM_TIMELINE_SCHEMA_ID;
}

console.log({TIDSROM_ZONE_IDS:Settings.TIDSROM_ZONE_IDS})